import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitleBox from "../components/PageTitleBox"

const IndexPage = () => (
  <Layout>
    <Seo
      keywords="特定商取引法に基づく表示,トナリノ,デザイン,デザイン相談"
      description="トナリノの特定商取引法に基づく表示をご確認いただけます。"
      ogTitle="特定商取引法に基づく表示"
      ogDescription="トナリノの特定商取引法に基づく表示ページです"
      ogUrl="https://tonarino.co/tokutei"
    />
    <PageTitleBox title="特定商取引法に基づく表示" />
    <section id="tokuteiContent">
      <div className="container">
        <h3 className="fs-4 fw-bold mb-3 mt-5">サービス名</h3>
        <p>トナリノ</p>

        <h3 className="fs-4 fw-bold mb-3 mt-5">住所</h3>
        <p>東京都港区南青山2-2-15</p>

        <h3 className="fs-4 fw-bold mb-3 mt-5">お問い合わせ先</h3>
        <p>
          こちらの<Link to="/hello">お問い合わせフォーム</Link>よりお問い合わせください。
        </p>

        <h3 className="fs-4 fw-bold mb-3 mt-5">販売価格</h3>
        <p>購入手続きの際に画面に表示されます。消費税は内税として表示しております。</p>

        <h3 className="fs-4 fw-bold mb-3 mt-5">サービス提供時期</h3>
        <p>購入後、お客様とお約束した日より</p>

        <h3 className="fs-4 fw-bold mb-3 mt-5">販売価格以外でお客様に発生する金銭</h3>
        <p>当サイトのページの閲覧、やりとり通話に必要となるインターネット接続料金、通信料金は、お客様のご負担となります。</p>

        <h3 className="fs-4 fw-bold mb-3 mt-5">お支払方法</h3>
        <p>クレジットカード決済</p>

        <h3 className="fs-4 fw-bold mb-3 mt-5">返品・キャンセル</h3>
        <p className="mb-5">
          商品の特性上、サービスの提供が開始した後は、お客様都合によるキャンセルは一切お受けできません。<br />
          お申し込みいただき、期限内にお支払いいただけなかった場合、自動的にキャンセル扱いといたします。
        </p>

        <p className="mb-5">※省略事項につきましてはお問い合わせがあった場合遅延なく開示いたします。</p>
      </div>
    </section>
  </Layout>
)

export default IndexPage
